<template>
  <section class="hero">
    <div class="above-the-fold app-width">
      <a :href="barLink" target="_blank" v-if="barLink && barTitle && barSubtitle">
        <div class="feature">
          <div>
            <span class="tag ai">AI</span>
          </div>
          <div class="content">
            <strong>
              {{barTitle}}
            </strong>
            <p>
              {{barSubtitle}}
            </p>
          </div>
          <div class="arrow-container">
            <IconSvg name="chevron-arrow" />
          </div>
        </div>
      </a>
      <div class="side-by-side">
        <div>
          <slot name="copy" />
        </div>
        <div class="video-container">
          <video playsinline muted loop controls width="100%" ref="video">
            <source :src="videoSrc" />
          </video>
          <Button :label="videoButtonLabel" class="rounded secondary" icon="play" @click="play" v-if="!playing"/>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import IconSvg from "@/components/atoms/IconSvg.vue";
  import Button from "@/components/atoms/Button.vue";
  export default Vue.extend({
    components: {
      Button,
      IconSvg
    },
    props: {
      barTitle: {
        type: String,
        default: ''
      },
      barSubtitle: {
        type: String,
        default: ''
      },
      barLink: {
        type: String,
        default: ''
      },
      videoSrc: {
        type: String,
        default: '/storyfolder-explainer.mp4'
      },
      videoButtonLabel: {
        type: String,
        default: 'See How It Works'
      }
    },
    data(){
      return {
        playing: false
      }
    },
    computed: {
      buttonLabel(){
        if(this.$root.os === 'mac'){
          return 'Download Free macOS app'
        }else if(this.$root.os === 'windows') {
          return 'Download Free Windows app'
        }else{
          return 'Download Free App'
        }
      }
    },
    mounted(){
      // when the video plays, set playing to true
      this.$refs.video.addEventListener('play', () => {
        this.playing = true;
      });
      // when the video pauses, set playing to false
      this.$refs.video.addEventListener('pause', () => {
        this.playing = false;
      });
    },
    methods: {
      play(){
        this.$refs.video.play();
        this.playing = true;
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    overflow: hidden;
    border-bottom: 0;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    display: flex;
  }
  .above-the-fold{
    position: relative;
    z-index: 1;
    text-shadow: 0 0 20px rgba(0,0,0,1);
    padding-top: 100px;
    padding-bottom: 8vh;
  }
  .side-by-side{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 2rem;
    text-align: center;
  }
  h1{
    line-height: 1.1em;
    padding-bottom: 0.8em;
    font-weight: 600;
    font-size: 1.9rem;
    overflow: visible;
    /deep/ span{
      background: -webkit-linear-gradient(45deg, #a7e5e0, #e0b2f5, #fc6169);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }
  }
  h4{
    color: @purpleVibrant;
    font-weight: 600;
    margin-top: 0;
  }
  ol{
    font-size: 1.25em;
    margin-bottom: 2em;
    li{
      padding-left: 0.25em;
      margin-bottom:0.5em;
      color: white;
      font-weight: 500;
    }
  }
  p{
    margin:0 auto 1em auto;
    line-height: 1.5em;
    font-size: 1.25rem;
    color: white;
    font-weight: 500;
  }

  .feature{
    background-image: url(/img/backgrounds/prism-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 1em;
    border-radius: 0.5em;
    font-size: 1.1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    margin-bottom: 2em;
    p{
      font-size: 1rem;
      margin: 0;
      color: @white;
    }
    span.tag.ai{
      background: @purpleVibrant;
      font-weight: 600;
      color: @white;
      text-transform: uppercase;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-size: 30px;
      box-shadow: 0px 0px 35px fade(@black, 50%);
    }
    .arrow-container{
      display: flex;
      align-items: center;
    }
  }

  .hero-image{
    width: 100%;
  }

  .os-list{
    margin: 1em auto 0 auto;
    line-height: 2em;
    font-size: inherit;
    .icon{
      height: 32px;
      width: 32px;
      zoom: .6;
    }
    span{
      white-space: nowrap;
    }
  }


  .video-container {
    position: relative;
    button{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15em;
      margin-top: -2em;
      margin-left: -7.5em;
      background: rgba(0,0,0,0.7);
      transition: none;
      &:hover{
        opacity: 1;
        background: rgba(0,0,0,0.9);
      }
    }
  }
  video{
    border-radius: 1rem;
    width: 100%;
    max-width: 1200px;
    cursor: pointer;
    aspect-ratio: 1.655/1;
  }

  @media(min-width: @zeroethbreakpoint){
    h1{
      padding-bottom: 0.3em;
      font-size: 2.5rem;
    }
  }

  @media(min-width: @firstbreakpoint){
    button{
      width: auto;
      margin: 0;
      display: inline-block;
    }
    .hero-image{
      width: 90%;
    }
    h1{
      padding-bottom: 0.3em;
      font-size: 2.75rem;
    }
    .feature{
      grid-template-columns: 70px 1fr 50px;
    }
  }
  @media(min-width: @secondbreakpoint){
    h1{
      font-size: 3.75rem;
      padding-bottom: 0.5em;
    }
    ul{
        font-size: 20px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    h1{
      font-size: 4.75rem;
    }
    ul{
        margin-bottom: 0;
    }
    p, p.first{
        font-size: 1.25em;
    }
  }
  @media(min-width: @fourthbreakpoint){
    .side-by-side{
      grid-template-columns: 1fr 1fr;
      text-align: left;
      justify-content: center;
      align-items: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
</style>
