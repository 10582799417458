<template>

  <AppPage :fix-header="true">
    <TheAgencyHero
        bar-link="http://google.com"
        bar-title="New features for social marketers & ad agencies"
        bar-subtitle="Discover what makes 'good' content actually work."
        bar-icon=""
    >
      <div slot="copy">
        <h4>White-Label Solutions</h4>
        <h1>
          <span>Your Secret Weapon for Social Success.</span>
        </h1>
        <p class="first">
          Decode high-performing content, gain deeper insights from client samples, craft compelling pitches, avoid quoting mistakes, and build trust that wins clients.
        </p>
      </div>
    </TheAgencyHero>
    <div class="metadata-section">
      <SectionTwoColumn class="app-width text-top">
        <div slot="graphic">
          <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
        </div>
        <div slot="text">
          <h1>
          <span  class="color-4">
            <IconSvg name="film" />
          </span>
            Demystify high-performing videos.
          </h1>
          <p>
            Use high-performing videos as blueprints for success. Discover what makes "good" content good. Import any video to break down structure, messaging, positioning, mood, tone, aesthetics and more.
          </p>
          <Button class="secondary wrap small" to="/b/646feaea6276ac594213adf5" style="margin-bottom: 0;" icon="link">
            Check out an example
          </Button>
        </div>
      </SectionTwoColumn>
      <SectionTwoColumn class="app-width text-top">
        <div slot="graphic">
          <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
        </div>
        <div slot="text">
          <h1>
        <span  class="color-4">
          <IconSvg name="film" />
        </span>
            Turn videos into project requirements.
          </h1>
          <p>
            Import any video to quickly identify production requirements like locations, setups, gear, crew, and time. Quickly document whatever you need to plan a successful production.
          </p>
          <Button class="secondary wrap small" to="/b/646feaea6276ac594213adf5" style="margin-bottom: 0;" icon="link">
            Check out an example
          </Button>
        </div>
      </SectionTwoColumn>
      <SectionTwoColumn class="app-width">
        <div slot="graphic">
          <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
        </div>
        <div slot="text">
          <h1>Make pitches that clients love.</h1>
          <p>
            Translate the production process to clients with transparent, easy-to-understand breakdowns. Build trust, demonstrate your expertise, and easily justify your pricing, making negotiation smooth and fast.
          </p>
        </div>
      </SectionTwoColumn>
      <SectionTwoColumn class="app-width">
        <div slot="graphic">
          <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
        </div>
        <div slot="text">
          <h1>Quote with confidence</h1>
          <p>
            Reduce underestimating, overestimating, scope creep, and lost bids. Detailed breakdowns ensure your quotes are accurate and reliable, giving you and your clients confidence in your estimates.
          </p>
        </div>
      </SectionTwoColumn>
      <SectionTwoColumn class="app-width">
        <div slot="graphic">
          <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
        </div>
        <div slot="text">
          <h1>Land more valuable projects.</h1>
          <p>
            Up your win rate by showing off your agency's skills and commitment to transparency. StoryFolder gives you the tools to make project requirements clear and understandable, which makes your proposals more compelling and helps you win more high-value projects.
          </p>
        </div>
      </SectionTwoColumn>
    </div>
  </AppPage>
</template>

<script lang="ts">
import AppPage from "@/components/organisms/AppPage.vue";

import SectionTwoColumn from "@/components/molecules/SectionTwoColumn.vue";
import TheAgencyHero from "@/components/organisms/TheAgencyHero.vue";


export default {
  components: {
    TheAgencyHero,
    SectionTwoColumn,
    AppPage,
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';


</style>
